<template>
    <v-container>
      <v-card flat>
        <v-card-title>
          <strong>Atedimento</strong>
        </v-card-title>
      </v-card>
    
      <v-row justify="center">   
        <v-col cols="12">   
          <v-data-table
            :loading="loading"
            :items="items"
            :search="search"
            :headers="headers"
          >
          <!--
            <template
              v-slot:top
            >
              <v-row dense>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    label="Pesquisar"
                    class="mx-0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="text-right">
                  <Tab :current-item="{}"/>
                </v-col>
                <v-col cols="1" class="text-right">
                  <v-btn icon @click="get_solicitacoes()"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-col>
              </v-row>
            </template>
  
            <template
              v-slot:[`item.papeis`]="{item}"
            >
              <span
                v-for="i in item.papel" 
                :key="`papel-${i.id}`"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs" 
                      v-on="on"
                      outlined
                      :color="i.cor" 
                      v-if="i.ativo"
                      class="ma-2"
                    >
                      {{i.titulo}}
                      <v-icon>{{i.icon}}</v-icon>
                    </v-chip>
                  </template>
                  <span>{{i.titulo}}</span>
                </v-tooltip>
              </span>
            </template>
            
            <template
              v-slot:[`item.edit`]="{item}"
            >
              <TrocaSenha :current-item="item" />
            </template>
  
            <template
              v-slot:[`item.config`]="{item}"
            >
              <Tab :current-item="item" />
            </template>
          -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  // import api from '@/http'
  export default {
    name:'Evolucoes',
    components:{
    },
    // data:()=>({
    //   search:'',
    //   loading:false,
    //   items:[],
    //   headers:[
    //     { text: 'Id',align: 'start',sortable: true, value: 'id',},
    //     { text: 'Nome',align: 'start',sortable: true, value: 'first_name',},
    //     { text: 'Sobre nome',align: 'end',sortable: true, value: 'last_name',},
    //     { text: 'e-mail',align: 'end',sortable: true, value: 'email',},
    //     { text: 'Usuário',align: 'start',sortable: true, value: 'username',},
    //     { text: 'Mudar Senha',align: 'end',sortable: true, value: 'edit',},
    //     { text: 'Editar usuario',align: 'end',sortable: true, value: 'config',},
    //   ],
    // }),
    // methods:{
    //   async get_solicitacoes(){
      
    //     this.loading = true
    //     api('contas/usuarios/')
    //     .then(response=>{
    //       this.items = response.data
    //     }).catch(error=>{
    //       if (error.response) {
    //         // Request made and server responded
    //         console.log(error.response.data);
    //         console.log(error.response.status);
    //         console.log(error.response.headers);
    //       } else if (error.request) {
    //         // The request was made but no response was received
    //         console.log(error.request);
    //       } else {
    //         // Something happened in setting up the request that triggered an Error
    //         console.log('Error', error.message);
    //       }
  
    //     }).finally(()=>{
    //       this.loading = false
    //     })
    //   },
    //   val_novo_usuario(){
    //     this.get_usuario()
    //   },
    // },computed:{
    // },mounted(){
    //   this.get_usuario()
    // }
  
  }
  </script>